import React, { Component } from "react";
// import Slider from "react-slick";
import image1 from "../../images/slider/1.jpg"
import image2 from "../../images/slider/4.jpg"
import image3 from "../../images/slider/3.jpg"
import {Animated} from "react-animated-css";
import './style.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SimpleSlider extends Component {
    render() {

      const settings = {
        autoPlay: true,
        showThumbs: false,
        stopOnHover: false,
        infiniteLoop: true,
        showStatus: false,
        showIndicators: false,
        interval: 4000,
        transitionTime: 400
      }

      return (
        <Carousel {...settings}>
            <div>
                <div id="home" className="item1">
                    <div className="container">
                        <div className="slide-content">
                          <div className="slide-subtitle">
                              <h4>BUDEME SA BRAŤ</h4>
                          </div>
                          <div className="slide-title">
                              <h2>Save Our Date</h2>
                          </div>
                          <div className="slide-text">
                              <p>27 August 2022</p>
                              <p>15:00</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                        </div>
                    </div>
                </div>
                <img src={image2} />
            </div>
            <div>
                <div id="home" className="item2">
                    <div className="container">
                        <div className="slide-content">
                          <div className="slide-subtitle">
                              <h4>BUDEME SA BRAŤ</h4>
                          </div>
                          <div className="slide-title">
                              <h2>Save Our Date</h2>
                          </div>
                          <div className="slide-text">
                              <p>27 August 2022</p>
                              <p>15:00</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                        </div>
                    </div>
                </div>
                <img src={image3} />
            </div>
            <div>
                <div id="home" className="item3">
                    <div className="container">
                        <div className="slide-content">
                          <div className="slide-subtitle">
                              <h4>BUDEME SA BRAŤ</h4>
                          </div>
                          <div className="slide-title">
                              <h2>Save Our Date</h2>
                          </div>
                          <div className="slide-text">
                              <p>27 August 2022</p>
                              <p>15:00</p>
                          </div>
                          <Animated>
                            <div className="animated-circle"></div>
                          </Animated>
                        </div>
                    </div>
                </div>
                <img src={image1} />
            </div>
        </Carousel>
      );
    }
  }

export default SimpleSlider;