import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLoading,
  IonCard,
  IonCardHeader,
  IonTextarea,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  useIonToast,
  IonToggle,
  IonInput,
} from "@ionic/react";
import "@ionic/react/css/core.css";
import { useEffect } from "react";
import axios from "axios";
import { wine, body, fastFood, personAdd } from "ionicons/icons";

const Invite = (props) => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [present] = useIonToast();
  const host = process.env.REACT_APP_API_HOST || "http://localhost:8787";

  const submit = async () => {
    await axios
      .post(`${host}/invite/${id}`, data)
      .then(() => {
        present({
          message: "Zmeny úspešne uložené",
          color: "success",
          duration: 3000,
          position: "top",
        });
      })
      .catch((error) => {
        setError(error.response.data.error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(`${host}/invite/${id}`);
        setData(response.data);
      } catch (error) {
        setError(error.response.data.error);
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const setFieldValue = (index, field, value) => {
    const people = [...data.people];
    const person = { ...people[index] };

    person[field] = value;

    people[index] = person;

    setData({
      ...data,
      people: [...people],
    });
  };

  if (error) {
    present({
      message: error,
      color: "danger",
      duration: 3000,
      position: "top",
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Pozvánka</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={loading} message="Načítavam" />
        {data && (
          <>
            {data.people.map((person, index) => (
              <IonCard key={index}>
                <IonCardHeader>
                  <IonCardTitle>{person.name}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonIcon icon={personAdd} slot="start" size="small" />
                    <IonLabel className="ion-text-wrap">
                      {person.attending ? "Zúčastním sa" : "Nezúčastním sa"} na
                      svadobnej hostine
                    </IonLabel>
                    <IonToggle
                      checked={person.attending}
                      onIonChange={(e) =>
                        setFieldValue(index, "attending", e.detail.checked)
                      }
                    ></IonToggle>
                  </IonItem>
                  {person.attending && (
                    <>
                      <IonItem hidden={person.kid}>
                        <IonIcon icon={wine} slot="start" size="small" />
                        <IonLabel>
                          {person.alcohol ? "Pijem" : "Nepijem"} alkohol
                        </IonLabel>
                        <IonToggle
                          checked={person.alcohol}
                          hidden={person.kid}
                          onIonChange={(e) =>
                            setFieldValue(index, "alcohol", e.detail.checked)
                          }
                        ></IonToggle>
                      </IonItem>
                      <IonItem>
                        <IonIcon icon={body} slot="start" size="small" />
                        <IonLabel>
                          Som Dieťa: {person.kid ? "Áno" : "Nie"}
                        </IonLabel>
                        <IonToggle
                          checked={person.kid}
                          onIonChange={(e) => {
                            setFieldValue(index, "kid", e.detail.checked);
                          }}
                        ></IonToggle>
                      </IonItem>
                      <IonItem>
                        <IonIcon icon={fastFood} slot="start" size="small" />
                        <IonTextarea
                          rows={3}
                          placeholder="Prosím vyplňte ak máte potravinové alergie/intolerancie alebo ste vegetarián."
                          value={person.food}
                          onIonChange={(e) =>
                            setFieldValue(index, "food", e.detail.value)
                          }
                        ></IonTextarea>
                      </IonItem>
                    </>
                  )}
                </IonCardContent>
              </IonCard>
            ))}
            <IonItem
              style={{
                marginBottom: "3vh",
              }}
            >
              <IonLabel position="stacked">
                Odkaz pre Veroniku a Mareka
              </IonLabel>
              <IonInput
                placeholder="..."
                value={data.other}
                onIonChange={(e) =>
                  setData({
                    ...data,
                    other: e.detail.value,
                  })
                }
              ></IonInput>
            </IonItem>
            <IonButton
              style={{
                marginBottom: "5vh",
              }}
              expand="block"
              onClick={() => submit()}
            >
              Uložiť
            </IonButton>
          </>
        )}
        {error && (
          <div style={{display: "flex", justifyContent: "center"}}>
            <div style={{textAlign: "center", maxWidth: "70vw", marginTop: "40vh"}}>
              Niekde sa stala chyba. Pokiaľ ste dostali QR kód s pozvánku všetko je v poriadku a ozvite sa mi. 0949 062 247</div>
            </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Invite;
