import React from 'react';
import './style.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import image2 from "../../images/slider/3.jpg"

const Footer = (props) => {
    const settings = {
      autoPlay: false,
      showThumbs: false,
      stopOnHover: false,
      infiniteLoop: true,
      showStatus: false,
      showIndicators: false,
    }

    return(
        <Carousel {...settings}>
            <div className='site-footer'>
                <div id="home" className="item1">
                    <div className="container">
                        <div className="row">
                            <div className="text">
                                <p>Ďakujeme</p>
                            </div>
                        </div>
                    </div> 
                </div>
                <img src={image2} />
            </div>
        </Carousel>
    )
}

export default Footer;