import React from  'react';

// components
import SimpleSlider from '../../components/hero';
import Couple from '../../components/couple';
import Story from '../../components/story';
import Welcome from '../../components/welcome-area';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Getting from '../../components/getting';
import Gift from '../../components/gift';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Footer from '../../components/footer'
import couple1 from '../../images/couple/veron.png'
import couple2 from '../../images/couple/marek2.png'

const HomeMain = () => {
    return(
       <div>
           <Navbar/>
           <SimpleSlider/>
           <Saveday saveday={'s2'}/>
           <Couple couple={'s2'} coupleimg1={couple1} coupleimg2={couple2}/>
           <Story/>
           <Location/>
           <Gift/>
           <Gallery/>
           <Footer footer={'s2'}/>
       </div>
    )
}

export default HomeMain;