import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomeMain from '../HomeMain/index'
import Scrollbar from '../../components/scrollbar'
import Invite from '../../components/invite';

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
            <Switch>
              <Route exact path='/' component={HomeMain} />
              <Route path='/invite/:id' component={Invite} />
              <Route path='/:id' component={HomeMain} />
            </Switch>
            <Scrollbar />
          </Router>
      
    </div>
  );
}

export default AllRoute;
