import React, { Component } from "react";
import Sectiontitle from "../section-title";
import "./style.css";

class Gift extends Component {
  render() {
    return (
      <div id="gifts" className="" style={{paddingBottom: "12vh"}}>
        <Sectiontitle section={"Dary"} />
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div
              className="col-lg-8 col-md-8 col-sm-8 col-12"
              style={{ textAlign: "center" }}
            >
              Chceli by sme Vás poprosiť, aby ste si nerobili starosti so
              svadobnými darmi. Ak nám chcete urobiť radosť, najviac sa potešíme
              príspevku na naše spoločné bývanie. Taktiež by sme Vás chceli
              požiadať, aby ste si s drahými kyticami nelámali hlavu. Ak nám
              chcete darovať kvety, budeme radi keď nám zaplatíte{" "}
              <a
                href="https://www.atelierpapaver.sk/produkt/kvetinove-predplatne-vytlacena-verzia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                kvetinové predplatné
              </a>
              , aby sme sa z kvetov mohli radovať počas celého roka. Ďakujeme
              veľmi pekne a tešíme sa, že náš veľký deň strávite s nami!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gift;
