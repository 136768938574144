import React from 'react'
import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import strory1 from '../../images/story/img-1.jpg'
import strory2 from '../../images/story/img-2.jpg'
import strory3 from '../../images/story/img-3.jpg'
import strory4 from '../../images/story/img-4.jpg'
import './style.css'


const Story = () =>{
    return(
        <div id="story" className="story-area section-padding">
            <Sectiontitle section={'Náš príbeh'}/>
            <div className="container">
                <div className="story-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory1} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Naše prvé stretnutia</h3>
                                <span className="date">2017 - 2018</span>
                                <p>Zoznámili sme sa v roku 2017 cez naších spoločných priateľov. </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Naše prvé rande</h3>
                                <span className="date">10 Júl 2018</span>
                                <p>V lete 2018 sme začali rozvíjať naše priateľstvo. Zažili sme spolu veľa príjemných zážitkov a momentov. Písal sa dátum 16.9.2018 keď sme si prvý krát povedali Áno. Rozprávali sme sa až do svitania a odvtedy to spolu ťaháme 😊</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory2} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pr-n">
                            <div className="story-img">
                                <img src={strory3} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text left-align-text">
                                <h3>Zásnuby</h3>
                                <span className="date">18 September 2021</span>
                                <p>V septembri 2021 sme išli na narodeninový výlet do Vysokých Tatier. Vtedy povedala Áno druhý krát 😊 Z výletu sme sa vracali zasnúbení, zasnúbili sme sa 18.9.2021.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="story-text right-align-text">
                                <h3>Snúbenci</h3>
                                <span className="date">September 2021 - August 2022</span>
                                <p>Ľúbime sa ako kone a tak sa berieme, aj keď nemusíme. <br/>Budeme radi ak to oslávite s nami!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 pl-n">
                            <div className="story-img">
                                <img src={strory4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

    )
}

export default Story;