
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Sectiontitle from '../section-title'
import strory1 from '../../images/events/kostol.jpg'
import strory2 from '../../images/events/sachticky.jpg'

import './style.css'
import { useParams } from 'react-router-dom';

const Location = (props) => {

    const {
        className
    } = props;

    const [mapa1, setMapa1] = useState(false);
    const [mapa2, setMapa2] = useState(false);
    const params = useParams();

    const toggle1 = () => setMapa1(!mapa1);
    const toggle2 = () => setMapa2(!mapa2);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (
        <div id="event" className="event-section section-padding">
            <Sectiontitle section={'Kedy & Kde'} />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="tabs-site-button">
                            <div className="event-tabs">
                                <div className="col-md-12 col-12">
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory1} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text">
                                                    <h3>Svadobný obrad</h3>
                                                    <span>27 August 2022 15:00</span>
                                                    <span>Kostol Panny Márie Pomocnice kresťanov</span>
                                                    <span>Tatranská 38/A, Banská Bystrica</span>
                                                    <p>Svadobný obrad sa začína o 15:00 v Kostole Panny Márie Pomocnice. Parkovať môžete priamo v areáli kostola, kde je dostatok parkovacích miest. Všetkých hostí prosíme, aby prišli v dostatočnom časovom predstihu, nakoľko do kostola pôjdeme všetci spoločne. </p>
                                                    <Button className="btn" onClick={toggle1}>Mapa</Button>
                                                    <Modal isOpen={mapa1} toggle={toggle1} className={className}>
                                                        <ModalHeader toggle={toggle1}>Mapa</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Tatransk%C3%A1%2038/A,%20Bansk%C3%A1%20Bystrica+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-wrap">
                                        <div className="row">
                                            <div className="col-lg-7 col-md-12 col-12">
                                                <div className="event-text event-text-2">
                                                    <h3>Svadobná Hostina</h3>
                                                    <span>27 August 2022 18:00</span>
                                                    <span>Hotel Šachtička, Banská Bystrica</span>
                                                    <p>Svadobná hostina bude prebiehať v Hoteli Šachtička, kam sa všetci po svadobnom obrade a gratuláciách presunieme na vlastných autách. Ak ste dostali pozvánku na svadobnú hostinu, potvrďte prosím svoju účasť na priloženom QR kóde.</p>
                                                    <Button className="btn" onClick={toggle2}>Mapa</Button>
                                                    {params.id && params.id.length === 6 && (
                                                        <Button className="btn" style={{marginTop: "1vh", marginBottom: "1vh", background: "#b2c9d3", color: "#fff"}} onClick={() => openInNewTab(`https://svadba.vigas.dev/invite/${params.id}`)}>Mám pozvánku</Button>
                                                    )}
                                                    <Modal isOpen={mapa2} toggle={toggle2} className={className}>
                                                        <ModalHeader toggle={toggle2}>Mapa</ModalHeader>
                                                        <ModalBody>
                                                            <div className="location-map">
                                                                <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Hotel%20%C5%A0achti%C4%8Dka+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-md-12 col-12">
                                                <div className="event-img">
                                                    <img src={strory2} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;
